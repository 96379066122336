import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const SelectSection = ({ name, id, required, value, onChange, selectOptions, optionDisplayStrategy }) => {

  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <>
            <Listbox.Button className='relative cursor-default rounded-s-md bg-white px-3 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300 sm:text-sm sm:leading-6'
            >
              <span className='flex items-center mr-16'>
                {/* <img src={selected.displayName} alt='' className='h-5 w-5 flex-shrink-0 rounded-full' /> */}
                <span className='block text-lg'>{value.name}</span>
              </span>
              <span className='pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2'>
              <svg className="h-20 w-20 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
        </svg>
              </span>
            </Listbox.Button>

            <Transition show={open} as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
              <Listbox.Options className='absolute top-10 z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                {selectOptions.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      classNames(active ? 'bg-gray-100' : '', 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9')
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className='flex items-center'>
                          {/* <img src={option.avatar} alt='' className='h-5 w-5 flex-shrink-0 rounded-full' /> */}
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block')}>{optionDisplayStrategy(option)}</span>
                        </div>

                        {/* {selected ? (
                          <span className={classNames(active ? 'text-white' : 'text-gray-300', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                            <CheckIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        ) : null} */}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
        </>
      )}
    </Listbox>
  );
};

export const InputSection = ({ className, type, name, id, placeholder, required, value, onChange }) => {
  return (
    <div className='relative rounded-e-md shadow-sm w-full'>
      <input
        type={type}
        name={name}
        id={id}
        className={classNames(!!className ? className : '', 'block w-full rounded-e-md border-0 py-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6')}
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default function SelectWithInput({
  inputType,
  label,
  inputPlaceholder,
  selectName,
  inputName,
  id,
  selectId,
  inputId,
  selectValue,
  inputValue,
  selectOnChange,
  inputOnChange,
  selectOptions,
  optionDisplayStrategy,
  selectRequired,
  inputRequired,
  inputClassName
}) {
  return (
    <>
      <label htmlFor={id} className='block text-base leading-6 text-gray-900'>
        {label}
        {(selectRequired || inputRequired) && <span className='text-red-500'>*</span>}
      </label>
      <div id={id} className='relative mt-2 flex justify-start w-full'>
        <SelectSection name={selectName} id={selectId} required={selectRequired} value={selectValue} onChange={selectOnChange} selectOptions={selectOptions} optionDisplayStrategy={optionDisplayStrategy} />
        <InputSection
          className={inputClassName}
          type={inputType}
          name={inputName}
          id={inputId}
          placeholder={inputPlaceholder}
          required={inputRequired}
          value={inputValue}
          onChange={inputOnChange}
        />
      </div>
    </>
  );
}
