import brandLogo from "../assets/icons/brand_logo.svg";
import section1_image from "../assets/images/section1_image.svg";
import section2_image from "../assets/images/section2_image.svg";
import Newsletter1 from "../assets/images/Newsletter1.png";
import Newsletter2 from "../assets/images/Newsletter2.png";
import Newsletter3 from "../assets/images/Newsletter3.png";
import Star from "../assets/images/Star.png";
import arrow from "../assets/images/arrow.png";
import TopImage from "../assets/images/Topimage.png";
import SideImage from "../assets/images/SideImage.png";
import SideImage2 from "../assets/images/SideImage2.png";
import quote from "../assets/images/quote.png";
import Vector from "../assets/images/Vector.svg";
import VectorY from "../assets/images/VectorY.png";
import flightIcon from "../assets/icons/flight.svg";
import youtube from "../assets/icons/youtube.svg";
import twitter from "../assets/icons/twitter.svg";
import instagram from "../assets/icons/instagram.svg";
import facebook from "../assets/icons/facebook.svg";
import Rectangle78 from "../assets/images/Rectangle78.png";
import C1 from "../assets/images/c1.svg";
import C2 from "../assets/images/c2.svg";
import whiteFlowerSvg from "../assets/images/white-flower.svg";
import yellowFlowerSvg from "../assets/images/yellow-flower.svg";
import greenFlowerSvg from "../assets/images/green-flower.svg";
import pinkFlowerSvg from "../assets/images/pink-flower.svg";
import creatorfolioSvg from "../assets/images/creatorfolio.svg";
import pinkESvg from "../assets/images/pink-e.svg";
import yellowESvg from "../assets/images/yellow-e.svg";
import yellowECutSvg from "../assets/images/yellow-e-cut.svg";
import greenESvg from "../assets/images/green-e.svg";
import pinkTriangleSvg from "../assets/images/pink-triangle.svg";
import lightYellowFlowerSvg from "../assets/images/light-yellow-flower.svg";
import landingIntroBoySvg from "../assets/images/landing-intro-boy.svg";
import landingIntroGirlSvg from "../assets/images/landing-intro-girl.svg";
import landingIntroGirlSvgMobile from "../assets/images/landing-intro-girl-mobile.svg";
import creator1 from "../assets/images/creator-1.svg";
import creator2 from "../assets/images/creator-2.svg";
import creator3 from "../assets/images/creator-3.svg";
import creator4 from "../assets/images/creator-4.webp";
import girlWithClouds from "../assets/images/girl-with-clouds.svg";
import yellowGreenCheck from "../assets/images/yellow-green-checkmark.svg";
import shieldBoy from "../assets/images/shield-boy.svg";
import landingArrows from "../assets/images/landing-arrows.svg";
import boat from "../assets/images/boat.svg";
import upArrowText from "../assets/images/up-arrow-text.svg";
import landingIntro from "../assets/images/landing-intro.svg";
import joinFormHeading from "../assets/images/join-form-heading.svg";
import introStar1 from "../assets/images/intro-star.svg";
import introStar2 from "../assets/images/intro-star-2.svg";
// remove in future
import userProfilePic from "../assets/images/kaushal-pp.png";

export const icons = {
  brandLogo,
  flightIcon,
  youtube,
  twitter,
  instagram,
  facebook,
};

export const images = {
  section1_image,
  section2_image,
  landingIntroGirlSvgMobile,
  Newsletter1,
  introStar1,
  introStar2,
  Newsletter2,
  Newsletter3,
  Star,
  Vector,
  VectorY,
  quote,
  TopImage,
  SideImage,
  SideImage2,
  arrow,
  Rectangle78,
  C1,
  C2,
  pinkFlowerSvg,
  yellowFlowerSvg,
  whiteFlowerSvg,
  greenFlowerSvg,
  pinkESvg,
  yellowESvg,
  yellowECutSvg,
  greenESvg,
  pinkTriangleSvg,
  creatorfolioSvg,
  userProfilePic,
  lightYellowFlowerSvg,
  landingIntroBoySvg,
  landingIntroGirlSvg,
  creator1,
  creator2,
  creator3,
  creator4,
  girlWithClouds,
  yellowGreenCheck,
  shieldBoy,
  landingArrows,
  boat,
  upArrowText,
  landingIntro,
  joinFormHeading,
};
