import React from "react";
import { images } from "../../../utils/images";

import "./HateDoing.scss";

const HateDoing = ({ styles }) => {
  return (
    <div className="landing-hate-doing-section" id="landing-hate-doing">
      <h1 className=" md:text-left font-[900] title font text-3xl text-center  ">
        We do all that you hate doing. On autopilot.
      </h1>
      <div className="points-section">
        <div className="points-container">
          <div className="points">
            <img src={images.yellowGreenCheck} alt="" />
            <div className="point">Client handling</div>
          </div>

          <div className="points">
            <img src={images.yellowGreenCheck} alt="" />
            <div className="point">Tooting your horn</div>
          </div>
          <div className="points">
            <img src={images.yellowGreenCheck} alt="" />
            <div className="point">1-click deal dashboard</div>
          </div>
          <div className="points">
            <img src={images.yellowGreenCheck} alt="" />
            <div className="point">Cracking deals</div>
          </div>
          <div className="points   ">
            <img src={images.yellowGreenCheck} alt="" />
            <div className="point">Streamlined payments</div>
          </div>
        </div>
        <img src={images.shieldBoy} alt="" id="shield-boy-img" />
      </div>
    </div>
  );
};

export default HateDoing;
