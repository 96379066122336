import React from "react";
import { images } from "../../../utils/images";

import "./Creators.scss";

const Creators = ({ styles }) => {
  return (
    <div className="landing-creators-section" id="landing-creators">
      <div className="title">
        Join the party with the <br /> world's best creators
      </div>
      <div className="creators-container">
        <div className="creator-card">
          <img src={images.creator1} alt="" />
          <div className="name">Selena/reeach.one</div>
        </div>
        <div className="creator-card">
          <img src={images.creator2} alt="" />
          <div className="name">Virat/reeach.one</div>
        </div>
        <div className="creator-card">
          <img src={images.creator3} alt="" />
          <div className="name">Messi/reeach.one</div>
        </div>
        <div className="creator-card">
          <img src={images.creator4} alt="" />
          <div className="name">Lewis/reeach.one</div>
        </div>
      </div>
    </div>
  );
};

export default Creators;
