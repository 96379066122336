import React, { FC } from "react";
import { icons, images } from "../../../utils/images";
const MailComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={27}
    fill="none"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.419 4.419h17.676c1.215 0 2.21.994 2.21 2.21v13.257c0 1.215-.995 2.21-2.21 2.21H4.42a2.216 2.216 0 0 1-2.21-2.21V6.628c0-1.215.995-2.21 2.21-2.21Z"
      style={{
        stroke: "#000",
        strokeOpacity: 1,
      }}
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m24.305 6.628-11.048 7.734L2.21 6.628"
      style={{
        stroke: "#000",
        strokeOpacity: 1,
      }}
    />
  </svg>
);
const InstaComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={27}
    fill="none"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M18.78 2.21H7.734A5.524 5.524 0 0 0 2.21 7.732v11.048a5.524 5.524 0 0 0 5.524 5.524h11.048a5.524 5.524 0 0 0 5.524-5.524V7.733A5.524 5.524 0 0 0 18.78 2.21Z"
      style={{
        stroke: "#000",
        strokeOpacity: 1,
      }}
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M17.676 12.561a4.42 4.42 0 1 1-8.743 1.297 4.42 4.42 0 0 1 8.743-1.297ZM19.333 7.181h.01"
      style={{
        stroke: "#000",
        strokeOpacity: 1,
      }}
    />
  </svg>
);

const Footer: FC = () => {
  return (
    <main className="md:px-[8vw] px-[4vw] min-h-[40vh] max-h-[50vh] md:min-h-[30vh] md:max-h-[40vh] w-screen  ">
      <h1 className="md:text-3xl text-xl font-semibold w-[80vw] md:w-[40vw] my-10">
        <span className=" font-light ">Let’s create magic together!</span> Need
        Help?{" "}
        <span className=" font-medium ">
          We’re here for you every step of the way.
        </span>
      </h1>
      <div className="md:flex md:flex-row  gap-10 md:mb-20 justify-between ">
        <div>
          <div className=" flex gap-4 cursor-pointer flex-row mb-10 ">
            <MailComponent /> 
            <p className="underline underline-offset-2"><a href="mailto:grow@reeach.one">grow@reeach.one</a></p>
          </div>
          <div className=" flex gap-4 cursor-pointer flex-row ">
            <InstaComponent />
            <p className="underline underline-offset-2"><a href="https://www.instagram.com/reeach.one/" target="_blank" rel="noreferrer">@reeach.one</a></p>
          </div>
        </div>

        <div className="flex flex-col py-10 md:py-0  md:items-end">
          <img src={icons.brandLogo} alt="brand-logo" className="h-[4vh] md:h-[6vh]" />
          <p className="text-center font-medium md:text-right">
            Reeach Team ❤️ Building for Creators
          </p>
        </div>
      </div>
    </main>
  );
};

export default Footer;
