import React, { useState } from 'react';
import { images } from '../../../utils/images';
import Checkbox from '../../shared/Checkbox/Checkbox.tsx';
import SelectField from '../../shared/SelectField/SelectField.tsx';
import InputField from '../../shared/InputField/InputField.tsx';
import './JoinForm.scss';
import SelectWithInput, { InputSection } from '../../shared/SelectWithInput/SelectWithInput.tsx';
import Autocomplete from '../../shared/Autocomplete/Autocomplete.tsx';
import SubmissionPopUp from './SubmissionPopUp.tsx';

const largestViewershipCountryOptions = [
  {
    id: '1',
    name: 'Afghanistan',
    value: 'Afghanistan',
  },
  {
    id: '2',
    name: 'Åland Islands',
    value: 'Åland Islands',
  },
  {
    id: '3',
    name: 'Albania',
    value: 'Albania',
  },
  {
    id: '4',
    name: 'Algeria',
    value: 'Algeria',
  },
  {
    id: '5',
    name: 'American Samoa',
    value: 'American Samoa',
  },
  {
    id: '6',
    name: 'AndorrA',
    value: 'AndorrA',
  },
  {
    id: '7',
    name: 'Angola',
    value: 'Angola',
  },
  {
    id: '8',
    name: 'Anguilla',
    value: 'Anguilla',
  },
  {
    id: '9',
    name: 'Antarctica',
    value: 'Antarctica',
  },
  {
    id: '10',
    name: 'Antigua and Barbuda',
    value: 'Antigua and Barbuda',
  },
  {
    id: '11',
    name: 'Argentina',
    value: 'Argentina',
  },
  {
    id: '12',
    name: 'Armenia',
    value: 'Armenia',
  },
  {
    id: '13',
    name: 'Aruba',
    value: 'Aruba',
  },
  {
    id: '14',
    name: 'Australia',
    value: 'Australia',
  },
  {
    id: '15',
    name: 'Austria',
    value: 'Austria',
  },
  {
    id: '16',
    name: 'Azerbaijan',
    value: 'Azerbaijan',
  },
  {
    id: '17',
    name: 'Bahamas',
    value: 'Bahamas',
  },
  {
    id: '18',
    name: 'Bahrain',
    value: 'Bahrain',
  },
  {
    id: '19',
    name: 'Bangladesh',
    value: 'Bangladesh',
  },
  {
    id: '20',
    name: 'Barbados',
    value: 'Barbados',
  },
  {
    id: '21',
    name: 'Belarus',
    value: 'Belarus',
  },
  {
    id: '22',
    name: 'Belgium',
    value: 'Belgium',
  },
  {
    id: '23',
    name: 'Belize',
    value: 'Belize',
  },
  {
    id: '24',
    name: 'Benin',
    value: 'Benin',
  },
  {
    id: '25',
    name: 'Bermuda',
    value: 'Bermuda',
  },
  {
    id: '26',
    name: 'Bhutan',
    value: 'Bhutan',
  },
  {
    id: '27',
    name: 'Bolivia',
    value: 'Bolivia',
  },
  {
    id: '28',
    name: 'Bosnia and Herzegovina',
    value: 'Bosnia and Herzegovina',
  },
  {
    id: '29',
    name: 'Botswana',
    value: 'Botswana',
  },
  {
    id: '30',
    name: 'Bouvet Island',
    value: 'Bouvet Island',
  },
  {
    id: '31',
    name: 'Brazil',
    value: 'Brazil',
  },
  {
    id: '32',
    name: 'British Indian Ocean Territory',
    value: 'British Indian Ocean Territory',
  },
  {
    id: '33',
    name: 'Brunei Darussalam',
    value: 'Brunei Darussalam',
  },
  {
    id: '34',
    name: 'Bulgaria',
    value: 'Bulgaria',
  },
  {
    id: '35',
    name: 'Burkina Faso',
    value: 'Burkina Faso',
  },
  {
    id: '36',
    name: 'Burundi',
    value: 'Burundi',
  },
  {
    id: '37',
    name: 'Cambodia',
    value: 'Cambodia',
  },
  {
    id: '38',
    name: 'Cameroon',
    value: 'Cameroon',
  },
  {
    id: '39',
    name: 'Canada',
    value: 'Canada',
  },
  {
    id: '40',
    name: 'Cape Verde',
    value: 'Cape Verde',
  },
  {
    id: '41',
    name: 'Cayman Islands',
    value: 'Cayman Islands',
  },
  {
    id: '42',
    name: 'Central African Republic',
    value: 'Central African Republic',
  },
  {
    id: '43',
    name: 'Chad',
    value: 'Chad',
  },
  {
    id: '44',
    name: 'Chile',
    value: 'Chile',
  },
  {
    id: '45',
    name: 'China',
    value: 'China',
  },
  {
    id: '46',
    name: 'Christmas Island',
    value: 'Christmas Island',
  },
  {
    id: '47',
    name: 'Cocos (Keeling) Islands',
    value: 'Cocos (Keeling) Islands',
  },
  {
    id: '48',
    name: 'Colombia',
    value: 'Colombia',
  },
  {
    id: '49',
    name: 'Comoros',
    value: 'Comoros',
  },
  {
    id: '50',
    name: 'Congo',
    value: 'Congo',
  },
  {
    id: '51',
    name: 'Congo, The Democratic Republic of the',
    value: 'Congo, The Democratic Republic of the',
  },
  {
    id: '52',
    name: 'Cook Islands',
    value: 'Cook Islands',
  },
  {
    id: '53',
    name: 'Costa Rica',
    value: 'Costa Rica',
  },
  {
    id: '54',
    name: 'Croatia',
    value: 'Croatia',
  },
  {
    id: '55',
    name: 'Cuba',
    value: 'Cuba',
  },
  {
    id: '56',
    name: 'Cyprus',
    value: 'Cyprus',
  },
  {
    id: '57',
    name: 'Czech Republic',
    value: 'Czech Republic',
  },
  {
    id: '58',
    name: 'Denmark',
    value: 'Denmark',
  },
  {
    id: '59',
    name: 'Djibouti',
    value: 'Djibouti',
  },
  {
    id: '60',
    name: 'Dominica',
    value: 'Dominica',
  },
  {
    id: '61',
    name: 'Dominican Republic',
    value: 'Dominican Republic',
  },
  {
    id: '62',
    name: 'Ecuador',
    value: 'Ecuador',
  },
  {
    id: '63',
    name: 'Egypt',
    value: 'Egypt',
  },
  {
    id: '64',
    name: 'El Salvador',
    value: 'El Salvador',
  },
  {
    id: '65',
    name: 'Equatorial Guinea',
    value: 'Equatorial Guinea',
  },
  {
    id: '66',
    name: 'Eritrea',
    value: 'Eritrea',
  },
  {
    id: '67',
    name: 'Estonia',
    value: 'Estonia',
  },
  {
    id: '68',
    name: 'Ethiopia',
    value: 'Ethiopia',
  },
  {
    id: '69',
    name: 'Falkland Islands (Malvinas)',
    value: 'Falkland Islands (Malvinas)',
  },
  {
    id: '70',
    name: 'Faroe Islands',
    value: 'Faroe Islands',
  },
  {
    id: '71',
    name: 'Fiji',
    value: 'Fiji',
  },
  {
    id: '72',
    name: 'Finland',
    value: 'Finland',
  },
  {
    id: '73',
    name: 'France',
    value: 'France',
  },
  {
    id: '74',
    name: 'French Guiana',
    value: 'French Guiana',
  },
  {
    id: '75',
    name: 'French Polynesia',
    value: 'French Polynesia',
  },
  {
    id: '76',
    name: 'French Southern Territories',
    value: 'French Southern Territories',
  },
  {
    id: '77',
    name: 'Gabon',
    value: 'Gabon',
  },
  {
    id: '78',
    name: 'Gambia',
    value: 'Gambia',
  },
  {
    id: '79',
    name: 'Georgia',
    value: 'Georgia',
  },
  {
    id: '80',
    name: 'Germany',
    value: 'Germany',
  },
  {
    id: '81',
    name: 'Ghana',
    value: 'Ghana',
  },
  {
    id: '82',
    name: 'Gibraltar',
    value: 'Gibraltar',
  },
  {
    id: '83',
    name: 'Greece',
    value: 'Greece',
  },
  {
    id: '84',
    name: 'Greenland',
    value: 'Greenland',
  },
  {
    id: '85',
    name: 'Grenada',
    value: 'Grenada',
  },
  {
    id: '86',
    name: 'Guadeloupe',
    value: 'Guadeloupe',
  },
  {
    id: '87',
    name: 'Guam',
    value: 'Guam',
  },
  {
    id: '88',
    name: 'Guatemala',
    value: 'Guatemala',
  },
  {
    id: '89',
    name: 'Guernsey',
    value: 'Guernsey',
  },
  {
    id: '90',
    name: 'Guinea',
    value: 'Guinea',
  },
  {
    id: '91',
    name: 'Guinea-Bissau',
    value: 'Guinea-Bissau',
  },
  {
    id: '92',
    name: 'Guyana',
    value: 'Guyana',
  },
  {
    id: '93',
    name: 'Haiti',
    value: 'Haiti',
  },
  {
    id: '94',
    name: 'Heard Island and Mcdonald Islands',
    value: 'Heard Island and Mcdonald Islands',
  },
  {
    id: '95',
    name: 'Holy See (Vatican City State)',
    value: 'Holy See (Vatican City State)',
  },
  {
    id: '96',
    name: 'Honduras',
    value: 'Honduras',
  },
  {
    id: '97',
    name: 'Hong Kong',
    value: 'Hong Kong',
  },
  {
    id: '98',
    name: 'Hungary',
    value: 'Hungary',
  },
  {
    id: '99',
    name: 'Iceland',
    value: 'Iceland',
  },
  {
    id: '100',
    name: 'India',
    value: 'India',
  },
  {
    id: '101',
    name: 'Indonesia',
    value: 'Indonesia',
  },
  {
    id: '102',
    name: 'Iran, Islamic Republic Of',
    value: 'Iran, Islamic Republic Of',
  },
  {
    id: '103',
    name: 'Iraq',
    value: 'Iraq',
  },
  {
    id: '104',
    name: 'Ireland',
    value: 'Ireland',
  },
  {
    id: '105',
    name: 'Isle of Man',
    value: 'Isle of Man',
  },
  {
    id: '106',
    name: 'Israel',
    value: 'Israel',
  },
  {
    id: '107',
    name: 'Italy',
    value: 'Italy',
  },
  {
    id: '108',
    name: 'Jamaica',
    value: 'Jamaica',
  },
  {
    id: '109',
    name: 'Japan',
    value: 'Japan',
  },
  {
    id: '110',
    name: 'Jersey',
    value: 'Jersey',
  },
  {
    id: '111',
    name: 'Jordan',
    value: 'Jordan',
  },
  {
    id: '112',
    name: 'Kazakhstan',
    value: 'Kazakhstan',
  },
  {
    id: '113',
    name: 'Kenya',
    value: 'Kenya',
  },
  {
    id: '114',
    name: 'Kiribati',
    value: 'Kiribati',
  },
  {
    id: '115',
    name: 'Korea, Republic of',
    value: 'Korea, Republic of',
  },
  {
    id: '116',
    name: 'Kuwait',
    value: 'Kuwait',
  },
  {
    id: '117',
    name: 'Kyrgyzstan',
    value: 'Kyrgyzstan',
  },
  {
    id: '118',
    name: 'Latvia',
    value: 'Latvia',
  },
  {
    id: '119',
    name: 'Lebanon',
    value: 'Lebanon',
  },
  {
    id: '120',
    name: 'Lesotho',
    value: 'Lesotho',
  },
  {
    id: '121',
    name: 'Liberia',
    value: 'Liberia',
  },
  {
    id: '122',
    name: 'Libyan Arab Jamahiriya',
    value: 'Libyan Arab Jamahiriya',
  },
  {
    id: '123',
    name: 'Liechtenstein',
    value: 'Liechtenstein',
  },
  {
    id: '124',
    name: 'Lithuania',
    value: 'Lithuania',
  },
  {
    id: '125',
    name: 'Luxembourg',
    value: 'Luxembourg',
  },
  {
    id: '126',
    name: 'Macao',
    value: 'Macao',
  },
  {
    id: '127',
    name: 'North Macedonia',
    value: 'North Macedonia',
  },
  {
    id: '128',
    name: 'Madagascar',
    value: 'Madagascar',
  },
  {
    id: '129',
    name: 'Malawi',
    value: 'Malawi',
  },
  {
    id: '130',
    name: 'Malaysia',
    value: 'Malaysia',
  },
  {
    id: '131',
    name: 'Maldives',
    value: 'Maldives',
  },
  {
    id: '132',
    name: 'Mali',
    value: 'Mali',
  },
  {
    id: '133',
    name: 'Malta',
    value: 'Malta',
  },
  {
    id: '134',
    name: 'Marshall Islands',
    value: 'Marshall Islands',
  },
  {
    id: '135',
    name: 'Martinique',
    value: 'Martinique',
  },
  {
    id: '136',
    name: 'Mauritania',
    value: 'Mauritania',
  },
  {
    id: '137',
    name: 'Mauritius',
    value: 'Mauritius',
  },
  {
    id: '138',
    name: 'Mayotte',
    value: 'Mayotte',
  },
  {
    id: '139',
    name: 'Mexico',
    value: 'Mexico',
  },
  {
    id: '140',
    name: 'Micronesia, Federated States of',
    value: 'Micronesia, Federated States of',
  },
  {
    id: '141',
    name: 'Moldova, Republic of',
    value: 'Moldova, Republic of',
  },
  {
    id: '142',
    name: 'Monaco',
    value: 'Monaco',
  },
  {
    id: '143',
    name: 'Mongolia',
    value: 'Mongolia',
  },
  {
    id: '144',
    name: 'Montserrat',
    value: 'Montserrat',
  },
  {
    id: '145',
    name: 'Morocco',
    value: 'Morocco',
  },
  {
    id: '146',
    name: 'Mozambique',
    value: 'Mozambique',
  },
  {
    id: '147',
    name: 'Myanmar',
    value: 'Myanmar',
  },
  {
    id: '148',
    name: 'Namibia',
    value: 'Namibia',
  },
  {
    id: '149',
    name: 'Nauru',
    value: 'Nauru',
  },
  {
    id: '150',
    name: 'Nepal',
    value: 'Nepal',
  },
  {
    id: '151',
    name: 'Netherlands',
    value: 'Netherlands',
  },
  {
    id: '152',
    name: 'Netherlands Antilles',
    value: 'Netherlands Antilles',
  },
  {
    id: '153',
    name: 'New Caledonia',
    value: 'New Caledonia',
  },
  {
    id: '154',
    name: 'New Zealand',
    value: 'New Zealand',
  },
  {
    id: '155',
    name: 'Nicaragua',
    value: 'Nicaragua',
  },
  {
    id: '156',
    name: 'Niger',
    value: 'Niger',
  },
  {
    id: '157',
    name: 'Nigeria',
    value: 'Nigeria',
  },
  {
    id: '158',
    name: 'Niue',
    value: 'Niue',
  },
  {
    id: '159',
    name: 'Norfolk Island',
    value: 'Norfolk Island',
  },
  {
    id: '160',
    name: 'Northern Mariana Islands',
    value: 'Northern Mariana Islands',
  },
  {
    id: '161',
    name: 'Norway',
    value: 'Norway',
  },
  {
    id: '162',
    name: 'Oman',
    value: 'Oman',
  },
  {
    id: '163',
    name: 'Pakistan',
    value: 'Pakistan',
  },
  {
    id: '164',
    name: 'Palau',
    value: 'Palau',
  },
  {
    id: '165',
    name: 'Palestinian Territory, Occupied',
    value: 'Palestinian Territory, Occupied',
  },
  {
    id: '166',
    name: 'Panama',
    value: 'Panama',
  },
  {
    id: '167',
    name: 'Papua New Guinea',
    value: 'Papua New Guinea',
  },
  {
    id: '168',
    name: 'Paraguay',
    value: 'Paraguay',
  },
  {
    id: '169',
    name: 'Peru',
    value: 'Peru',
  },
  {
    id: '170',
    name: 'Philippines',
    value: 'Philippines',
  },
  {
    id: '171',
    name: 'Pitcairn Islands',
    value: 'Pitcairn Islands',
  },
  {
    id: '172',
    name: 'Poland',
    value: 'Poland',
  },
  {
    id: '173',
    name: 'Portugal',
    value: 'Portugal',
  },
  {
    id: '174',
    name: 'Puerto Rico',
    value: 'Puerto Rico',
  },
  {
    id: '175',
    name: 'Qatar',
    value: 'Qatar',
  },
  {
    id: '176',
    name: 'Reunion',
    value: 'Reunion',
  },
  {
    id: '177',
    name: 'Romania',
    value: 'Romania',
  },
  {
    id: '178',
    name: 'Russian Federation',
    value: 'Russian Federation',
  },
  {
    id: '179',
    name: 'Rwanda',
    value: 'Rwanda',
  },
  {
    id: '180',
    name: 'Saint Helena',
    value: 'Saint Helena',
  },
  {
    id: '181',
    name: 'Saint Kitts and Nevis',
    value: 'Saint Kitts and Nevis',
  },
  {
    id: '182',
    name: 'Saint Lucia',
    value: 'Saint Lucia',
  },
  {
    id: '183',
    name: 'Saint Pierre and Miquelon',
    value: 'Saint Pierre and Miquelon',
  },
  {
    id: '184',
    name: 'Saint Vincent and the Grenadines',
    value: 'Saint Vincent and the Grenadines',
  },
  {
    id: '185',
    name: 'Samoa',
    value: 'Samoa',
  },
  {
    id: '186',
    name: 'San Marino',
    value: 'San Marino',
  },
  {
    id: '187',
    name: 'Sao Tome and Principe',
    value: 'Sao Tome and Principe',
  },
  {
    id: '188',
    name: 'Saudi Arabia',
    value: 'Saudi Arabia',
  },
  {
    id: '189',
    name: 'Senegal',
    value: 'Senegal',
  },
  {
    id: '190',
    name: 'Serbia and Montenegro',
    value: 'Serbia and Montenegro',
  },
  {
    id: '191',
    name: 'Seychelles',
    value: 'Seychelles',
  },
  {
    id: '192',
    name: 'Sierra Leone',
    value: 'Sierra Leone',
  },
  {
    id: '193',
    name: 'Singapore',
    value: 'Singapore',
  },
  {
    id: '194',
    name: 'Slovakia',
    value: 'Slovakia',
  },
  {
    id: '195',
    name: 'Slovenia',
    value: 'Slovenia',
  },
  {
    id: '196',
    name: 'Solomon Islands',
    value: 'Solomon Islands',
  },
  {
    id: '197',
    name: 'Somalia',
    value: 'Somalia',
  },
  {
    id: '198',
    name: 'South Africa',
    value: 'South Africa',
  },
  {
    id: '199',
    name: 'South Georgia and the South Sandwich Islands',
    value: 'South Georgia and the South Sandwich Islands',
  },
  {
    id: '200',
    name: 'Spain',
    value: 'Spain',
  },
  {
    id: '201',
    name: 'Sri Lanka',
    value: 'Sri Lanka',
  },
  {
    id: '202',
    name: 'Sudan',
    value: 'Sudan',
  },
  {
    id: '203',
    name: 'Suriname',
    value: 'Suriname',
  },
  {
    id: '204',
    name: 'Svalbard and Jan Mayen',
    value: 'Svalbard and Jan Mayen',
  },
  {
    id: '205',
    name: 'Swaziland',
    value: 'Swaziland',
  },
  {
    id: '206',
    name: 'Sweden',
    value: 'Sweden',
  },
  {
    id: '207',
    name: 'Switzerland',
    value: 'Switzerland',
  },
  {
    id: '208',
    name: 'Syrian Arab Republic',
    value: 'Syrian Arab Republic',
  },
  {
    id: '209',
    name: 'Taiwan',
    value: 'Taiwan',
  },
  {
    id: '210',
    name: 'Tajikistan',
    value: 'Tajikistan',
  },
  {
    id: '211',
    name: 'Tanzania, United Republic of',
    value: 'Tanzania, United Republic of',
  },
  {
    id: '212',
    name: 'Thailand',
    value: 'Thailand',
  },
  {
    id: '213',
    name: 'Timor-Leste',
    value: 'Timor-Leste',
  },
  {
    id: '214',
    name: 'Togo',
    value: 'Togo',
  },
  {
    id: '215',
    name: 'Tokelau',
    value: 'Tokelau',
  },
  {
    id: '216',
    name: 'Tonga',
    value: 'Tonga',
  },
  {
    id: '217',
    name: 'Trinidad and Tobago',
    value: 'Trinidad and Tobago',
  },
  {
    id: '218',
    name: 'Tunisia',
    value: 'Tunisia',
  },
  {
    id: '219',
    name: 'Turkey',
    value: 'Turkey',
  },
  {
    id: '220',
    name: 'Turkmenistan',
    value: 'Turkmenistan',
  },
  {
    id: '221',
    name: 'Turks and Caicos Islands',
    value: 'Turks and Caicos Islands',
  },
  {
    id: '222',
    name: 'Tuvalu',
    value: 'Tuvalu',
  },
  {
    id: '223',
    name: 'Uganda',
    value: 'Uganda',
  },
  {
    id: '224',
    name: 'Ukraine',
    value: 'Ukraine',
  },
  {
    id: '225',
    name: 'United Arab Emirates',
    value: 'United Arab Emirates',
  },
  {
    id: '226',
    name: 'United Kingdom',
    value: 'United Kingdom',
  },
  {
    id: '227',
    name: 'United States',
    value: 'United States',
  },
  {
    id: '228',
    name: 'United States Minor Outlying Islands',
    value: 'United States Minor Outlying Islands',
  },
  {
    id: '229',
    name: 'Uruguay',
    value: 'Uruguay',
  },
  {
    id: '230',
    name: 'Uzbekistan',
    value: 'Uzbekistan',
  },
  {
    id: '231',
    name: 'Vanuatu',
    value: 'Vanuatu',
  },
  {
    id: '232',
    name: 'Venezuela',
    value: 'Venezuela',
  },
  {
    id: '233',
    name: 'Vietnam',
    value: 'Vietnam',
  },
  {
    id: '234',
    name: 'Virgin Islands, British',
    value: 'Virgin Islands, British',
  },
  {
    id: '235',
    name: 'Virgin Islands, U.S.',
    value: 'Virgin Islands, U.S.',
  },
  {
    id: '236',
    name: 'Wallis and Futuna',
    value: 'Wallis and Futuna',
  },
  {
    id: '237',
    name: 'Western Sahara',
    value: 'Western Sahara',
  },
  {
    id: '238',
    name: 'Yemen',
    value: 'Yemen',
  },
  {
    id: '239',
    name: 'Zambia',
    value: 'Zambia',
  },
  {
    id: '240',
    name: 'Zimbabwe',
    value: 'Zimbabwe',
  },
  {
    id: '241',
    name: 'Other',
    value: 'Other',
  },
];

const monthlyEarningsFromBrandSponsorshipOptions = [
  { id: '1', name: '< $1000', value: '$0-$100' },
  { id: '2', name: '$1000 - $5000', value: '$1000-$5000' },
  { id: '3', name: '$5000 - $10000', value: '$5000-$10000' },
  { id: '4', name: 'More than $10000', value: '$10000+' },
];

const nicheOptions = [
  { id: '1', name: 'The Lifestyle Blogger', value: 'The Lifestyle Blogger' },
  { id: '2', name: 'The Tech Geek', value: 'The Tech Geek' },
  { id: '3', name: 'The Travel Adventurer', value: 'The Travel Adventurer' },
  {
    id: '4',
    name: 'The DIY and Craft Maven',
    value: 'The DIY and Craft Maven',
  },
  { id: '5', name: 'The Gaming Streamer', value: 'The Gaming Streamer' },
  { id: '6', name: 'The Beauty Guru', value: 'The Beauty Guru' },
  {
    id: '7',
    name: 'The Pet Lover and Animal Advocate',
    value: 'The Pet Lover and Animal Advocate',
  },
  { id: '8', name: 'The Fitness Enthusiast', value: 'The Fitness Enthusiast' },
  { id: '9', name: 'The Foodie Influencer', value: 'The Foodie Influencer' },
  { id: '10', name: 'The Parenting Blogger', value: 'The Parenting Blogger' },
  { id: '11', name: 'The Social Activists', value: 'The Social Activists' },
  { id: '12', name: 'The Educator & Mentor', value: 'The Educator & Mentor' },
  {
    id: '13',
    name: 'The Comedy & Entertainment Creator',
    value: 'The Comedy & Entertainment Creator',
  },
];

const socialMediaOptions = [
  { id: '1', name: 'Instagram', value: 'Instagram' },
  { id: '2', name: 'YouTube', value: 'YouTube' },
  { id: '3', name: 'TikTok', value: 'TikTok' },
  { id: '4', name: 'LinkedIn', value: 'LinkedIn' },
  { id: '5', name: 'Podcast', value: 'Podcast' },
  { id: '6', name: 'Newsletter', value: 'Newsletter' },
  { id: '7', name: 'Blog', value: 'Blog' },
];

const JoinForm = ({ styles }) => {
  const [isEnabled, setIsEnabled] = useState(false);
  return (
    <div className=' bg-[#ffe266] md:flex py-[5vw] md:flex-row w-full  px-[4vw] md:px-[8vw] justify-between ' id='landing-join-form'>
      {isEnabled && <SubmissionPopUp toggleEnabled={setIsEnabled} />}
      {/* for medium screens */}
      <div className='md:left-section grid-cols-1 md:block hidden'>
        <div className='section-heading'>
          <img src={images.joinFormHeading} alt='' />
        </div>
        <p className='section-subheading text-xl mt-32'>Mind having us together on this ride?</p>
        <img className='boat' src={images.boat} alt='' id='boat-img' />
      </div>
      <div className='block md:hidden'>
        <h1 className='text-3xl  w-[100%] px-[5%] font-black text-center '>You are living our dream of creating for the world.</h1>
        <p className='text-center my-4'>Mind having us together on this ride?</p>
      </div>
      <div className='right-section grid-cols-2 bg-white rounded-3xl px-8 py-14 font-semibold h-fit my-auto'>
        <Form styles={styles} isEnabled={setIsEnabled} />
      </div>
      <img className='boat -mt-[4vh] block md:hidden ' src={images.boat} alt='' id='boat-img' />
    </div>
  );
};

const Form = ({ styles, isEnabled }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneCountryCode, setPhoneCountryCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [largestViewershipCountry, setLargestViewershipCountry] = useState({} as { id: string; name: string; value: string });
  const [monthlyEarningsFromBrandSponsorship, setMonthlyEarningsFromBrandSponsorship] = useState({} as { id: string; name: string; value: string });
  const [niches, setNiches] = useState<{ id: string; name: string; value: string }[]>([]);
  const [haveManagement, setHaveManagement] = useState(false);
  const [callAvailability, setCallAvailability] = useState(false);
  const [largestViewershipCountryErrorState, setLargestViewershipCountryErrorState] = useState(false);
  const [monthlyEarningsFromBrandSponsorshipErrorState, setMonthlyEarningsFromBrandSponsorshipErrorState] = useState(false);
  const [nichesErrorState, setNichesErrorState] = useState(false);
  const [socialMediaErrorState, setSocialMediaErrorState] = useState(false);
  type FormStatus = 'pending' | 'submitting' | 'submitted';
  const [formStatus, setFormStatus] = useState<FormStatus>('pending');

  const [socialMedias, setSocialMedias] = useState([] as { type: { id: string; name: string; value: string }; link: string }[]);

  const addSocialMedia = () => {
    setSocialMediaErrorState(false);
    setSocialMedias((s) => [...s, { type: {} as { id: string; name: string; value: string }, link: '' }]);
  };

  const setSocialMediaType = (index, type) => {
    setSocialMediaErrorState(false);
    setSocialMedias((s) => {
      const newSocialMedias = JSON.parse(JSON.stringify(s));
      newSocialMedias[index].type = type;
      return newSocialMedias;
    });
  };

  const setSocialMediaLink = (index, link) => {
    setSocialMediaErrorState(false);
    setSocialMedias((s) => {
      const newSocialMedias = JSON.parse(JSON.stringify(s));
      newSocialMedias[index].link = link;
      return newSocialMedias;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!largestViewershipCountry.value) {
      setLargestViewershipCountryErrorState(true);
    }

    if (!monthlyEarningsFromBrandSponsorship.value) {
      setMonthlyEarningsFromBrandSponsorshipErrorState(true);
    }

    if (niches.length === 0) {
      setNichesErrorState(true);
    }

    if (socialMedias.length === 0 || socialMedias.some((s) => !s.type || !s.link || !s.link.trim())) {
      setSocialMediaErrorState(true);
    }

    if (
      !largestViewershipCountry.value ||
      !monthlyEarningsFromBrandSponsorship.value ||
      niches.length === 0 ||
      socialMedias.length === 0 ||
      socialMedias.some((s) => !s.type || !s.link || !s.link.trim())
    ) {
      return;
    }

    setFormStatus('submitting');

    const firstName = name.split(' ')[0];

    const phoneString = !phoneCountryCode.trim() || !phoneNumber.trim() ? '' : `${phoneCountryCode.trim()} ${phoneNumber.trim()}`;

    let socialMediaString = socialMedias
      .filter((s) => s.type && s.link && s.link.trim())
      .map((s) => `${s.type.value}: ${s.link}`)
      .join(', ');

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('phone', phoneString);
    formData.append('largestViewershipCountry', largestViewershipCountry.value);
    formData.append('monthlyEarningsFromBrandSponsorships', monthlyEarningsFromBrandSponsorship.value);
    formData.append('niches', niches.map((niche) => niche.value).join(','));
    formData.append('socialMedias', socialMediaString);
    formData.append('haveManagement', '' + haveManagement);
    formData.append('quickCall', '' + callAvailability);

    const url = 'https://script.google.com/macros/s/AKfycbz2R_NEfeEJHFaUH37Dqdvprxqc-5B5HZc_9Zo6GsBvCGGx0ptvVdDCCAyQzGnv2-kX/exec';

    fetch(url, { method: 'POST', body: formData })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log('Form submitted', {
          name,
          email,
          largestViewershipCountry,
          monthlyEarningsFromBrandSponsorship,
          niches,
          haveManagement,
          callAvailability,
        });
        setFormStatus('submitted');
        isEnabled(true);
        const emailSubject = `${firstName}, you got Reeach!`;
        const emailHtml = `
        <p>Hey ${firstName},</p>
        <p>Thanks for showing your interest to get Reeach!</p>
        <p>We’d love to have you on board with our beta program :)</p>
        <p>To get your profile noticed to relevant brands, we’ll need a few details. Can we speak for 15 minutes anytime this week?</p>
        <p>Please feel free to block a slot basis your availability here: <a href="https://calendar.app.google/iuAhmzJhWB6Csi6L8">https://calendar.app.google/iuAhmzJhWB6Csi6L8</a></p>
        <p>Creating for you,</p>
        <div>Rajeev</div>
        `;
        sendEmail(email, emailSubject, '', emailHtml);
      })
      .catch((err) => console.log(err));
  };

  const sendEmail = (to, subject, text, html) => {
    fetch('https://api.reeach.one/api/misc/send-email', {
      method: 'POST',
      body: JSON.stringify({ to, subject, text, html }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  };

  const formatCountryCode = (code) => {
    if (!code || code.length === 0) return '';
    if (code === '+') return '';
    return code.startsWith('+') ? code : '+' + code;
  };

  return (
    <form className='flex flex-col  space-y-7' onSubmit={(e) => handleSubmit(e)}>
      <InputField
        label='Your Full Name'
        type='text'
        id='fullName'
        placeholder='John Doe'
        name='fullName'
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <InputField
        label='Your Email ID'
        type='email'
        id='email'
        name='email'
        placeholder='john.doe@gmail.com'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />

      <label htmlFor='phone' className='block text-base leading-6 text-gray-900'>
        Your Phone Number
        {/* <span className='text-red-500'>*</span> */}
      </label>
      <div id='phone' className='relative mt-2 flex justify-start w-full phone-container'>
        <InputSection
          className=''
          type='text'
          name='phoneCountryCode'
          id='phoneCountryCode'
          placeholder='+1'
          required={false}
          value={phoneCountryCode}
          onChange={(e) => setPhoneCountryCode(formatCountryCode(e.target.value))}
        />
        <InputSection
          className=''
          type='number'
          name='phoneInput'
          id='phoneInput'
          placeholder='9876543210'
          required={false}
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </div>

      {/* <SelectWithInput
        inputType="number"
        label="Your Phone Number"
        inputPlaceholder={"9876543210"}
        selectName="phoneCountryCode"
        inputName="phoneInput"
        id="phone"
        selectId="phoneCountryCode"
        inputId="phoneInput"
        selectValue={phoneCountryCode}
        inputValue={phoneNumber}
        selectOnChange={(opt) => setPhoneCountryCode(opt as any)}
        inputOnChange={(e) => setPhoneNumber(e.target.value)}
        selectOptions={dialCodeFlagOptions}
        optionDisplayStrategy={(opt) =>
          `${opt.name} ${opt.countryName} (${opt.value})`
        }
        inputRequired={false}
        selectRequired={false}
      /> */}

      {/* <InputField
        label='Your Phone Number'
        type='number'
        id='phone'
        name='phone'
        placeholder='+1 (555) 987-6543'
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      /> */}
      {/* <SelectField
        value={largestViewershipCountry}
        onChange={(opt) => setLargestViewershipCountry(opt as any)}
        placeholder="Select country"
        label="Largest viewership country"
        options={largestViewershipCountryOptions}
        required
        errorState={largestViewershipCountryErrorState}
        setErrorState={setLargestViewershipCountryErrorState}
      /> */}

      <Autocomplete
        value={largestViewershipCountry}
        onChange={(opt) => setLargestViewershipCountry(opt as any)}
        placeholder='Select country'
        label='Largest viewership country'
        options={largestViewershipCountryOptions}
        required
        errorState={largestViewershipCountryErrorState}
        setErrorState={setLargestViewershipCountryErrorState}
      />

      <SelectField
        value={niches}
        onChange={(opt) => setNiches(opt as any)}
        placeholder='Select upto 3 niches'
        label='Your niches'
        options={nicheOptions}
        multiple
        maxSelections={3}
        required
        errorState={nichesErrorState}
        setErrorState={setNichesErrorState}
      />

      <SelectField
        value={monthlyEarningsFromBrandSponsorship}
        onChange={(opt) => setMonthlyEarningsFromBrandSponsorship(opt as any)}
        placeholder='Select revenue'
        label='Monthly earnings from brand sponsorship'
        options={monthlyEarningsFromBrandSponsorshipOptions}
        required
        errorState={monthlyEarningsFromBrandSponsorshipErrorState}
        setErrorState={setMonthlyEarningsFromBrandSponsorshipErrorState}
      />

      <div>
        <div className='flex items-center'>
          <label>
            Add social media links<span className='text-red-500'>*</span>
          </label>
          <button
            onClick={(e) => {
              e.preventDefault();
              addSocialMedia();
            }}
            className={`ml-10 ${socialMediaErrorState ? 'bg-red-200' : 'bg-gray-200'} border-none outline-none rounded-3xl px-3 py-1`}
          >
            +
          </button>
        </div>
        {socialMedias.map((socialMedia, index) => (
          <div key={index} className='mb-20'>
            <SelectField
              value={socialMedia.type}
              onChange={(opt) => setSocialMediaType(index, opt as any)}
              placeholder='Select platform'
              label=''
              options={socialMediaOptions.filter((s) => !socialMedias.map((s) => s.type.id).includes(s.id))}
            />
            <div className='mb-10' />
            <InputField
              label=''
              type='text'
              id={socialMedia.type.name}
              placeholder='Profile Link'
              name={socialMedia.type.name}
              value={socialMedia.link}
              onChange={(e) => setSocialMediaLink(index, e.target.value)}
            />
          </div>
        ))}
      </div>

      <Checkbox
        id='haveManagement'
        name='haveManagement'
        label='I have a manager or management team.'
        checked={haveManagement}
        onChange={(e) => setHaveManagement((x) => !x)}
      />
      <Checkbox
        id='callAvailability'
        name='callAvailability'
        label='I am up for a quick call.'
        checked={callAvailability}
        onChange={(e) => setCallAvailability((x) => !x)}
      />

      {formStatus === 'pending' ? (
        <button type='submit' className='creator-btn mt-30'>
          Start for Free
        </button>
      ) : formStatus === 'submitting' ? (
        <Loader />
      ) : (
        <div className='text-center text-green-800 mt-28'>Form submitted successfully</div>
      )}
    </form>
  );
};

const Loader = () => {
  return (
    <div role='status' className='mx-auto overflow-hidden'>
      <svg
        aria-hidden='true'
        className='inline w-26 h-26 text-gray-200 animate-spin dark:text-gray-200 fill-green-700'
        viewBox='0 0 100 101'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
          fill='currentColor'
        />
        <path
          d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
          fill='currentFill'
        />
      </svg>
    </div>
  );
};

export default JoinForm;
