import React from "react";
import { images } from "../../../utils/images";

import "./GetReeach.scss";

const reeechReasons = [
  {
    id: "01",
    point: "Display your best work.",
  },
  {
    id: "02",
    point: "Serve numbers that brands are hungry for.",
  },
  {
    id: "03",
    point: "Share your services and rates.",
  },
  {
    id: "04",
    point: "Get Booked. Collaborate.",
  },
];

const GetReeach = ({ styles }) => {
  return (
    <div className="landing-get-reeach-section" id="landing-get-reeach">
      <h1 className="title">Let's get you reeach?</h1>
      <div className="flex-section">
        <div className="left-section">
          <h1 className="subtitle">
            This tried-and-tested process has worked for hundreds of brands and
            creators—or should we say has got them Reeach!
          </h1>
        </div>
        <div className="right-section">
          <div className="pointers">
            {reeechReasons.map((reason) => (
              <div className="pointer" key={reason.id}>
                <p className="count">{reason.id}</p>
                <p className="point">{reason.point}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <img
        src={images.landingArrows}
        alt=""
        className=" sm:hidden md:block "
        id="landing-arrows-img"
      />
    </div>
  );
};

export default GetReeach;
