import React from 'react';

const InputField = ({
  label,
  type,
  id,
  name,
  placeholder,
  value,
  onChange,
  required,
}: {
  label: string;
  type: string;
  id: string;
  name: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
}) => {
  return (
    <div>
      <label htmlFor={id} className='block text-base leading-6 text-gray-900'>
        {label}
        {required && <span className='text-red-500'>*</span>}
      </label>
      <div className='relative mt-2 rounded-md shadow-sm'>
        <input
          type={type}
          name={name}
          id={id}
          className='block w-full rounded-md border-0 py-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6'
          placeholder={placeholder}
          required={required}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default InputField;
