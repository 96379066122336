import { Routes, Route } from "react-router-dom";

import Creatorfolio from "./pages/Creatorfolio.jsx";
import Services from "./pages/Services";
import Statistics from "./pages/Statistics";
import Header from "./components/shared/Header/Header.tsx";
import Landing from "./pages/Landing.jsx";

import styles from "./components/home.module.css";
import "./App.scss";

function App() {
  return (
    <div className="app">
      <Header styles={styles} />
      <Routes>
        <Route path="/" element={<Landing />} />
        {/* <Route path="/createrfolio" element={<Creatorfolio />} />
        <Route path="/createrfolio/services" element={<Services />} />
        <Route path="/createrfolio/stats" element={<Statistics />} /> */}
        {/* <Route path='/login' element={<Login/>} /> */}
        {/* <Route path='/signup' element={<Signup/>} /> */}
      </Routes>
    </div>
  );
}

export default App;
