import React from "react";
import Intro from "../components/Landing/Intro/Intro.tsx";
import Creators from "../components/Landing/Creators/Creators.tsx";
import LoveCreators from "../components/Landing/LoveCreators/LoveCreators.tsx";
import HateDoing from "../components/Landing/HateDoing/HateDoing.tsx";
import GetReeach from "../components/Landing/GetReeach/GetReeach.tsx";
import JoinForm from "../components/Landing/JoinForm/JoinForm.tsx";
import Footer from "../components/shared/Footer/Footer.tsx";

const Landing = () => {
  return (
    <div className="page-container">
      <Intro />
      <Creators />
      <LoveCreators />
      <HateDoing />
      <GetReeach />
      <JoinForm />
      <Footer />
    </div>
  );
};

export default Landing;
