import React from 'react';

const Checkbox = ({ label, id, name, checked, onChange }) => {
  return (
    <label htmlFor={id} className='mt-3 flex items-center'>
      <input
        id={id}
        name={name}
        type='checkbox'
        className='form-checkbox h-18 w-18 text-green-800 rounded-sm outline-none focus:outline-none focus:shadow-none focus:ring-transparent'
        checked={checked}
        onChange={onChange}
      />
      <span className='ml-8 text-gray-900'>{label}</span>
    </label>
  );
};

export default Checkbox;
