import React from "react";

const Close = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17.75 1.75 1.5 18.25M1.5 1.75l16.25 16.5"
      style={{
        stroke: "#fff",
        strokeOpacity: 1,
      }}
    />
  </svg>
);

const SubmissionPopUp = ({ toggleEnabled }) => {
  return (
    <div className="w-[100vw] -mx-[8vw]  h-[100vh] z-40 rounded absolute flex flex-col items-center top-28 ">
      <div className="md:w-[40vw] w-[80%] h-[50%] md:h-[40vh] flex flex-col items-center px-[5%] md:px-[2%]  rounded-xl shadow-md bg-[#F187BA]">
        <div className="w-full flex flex-row justify-end md:mt-[2%] mt-[5%] ">
          <button onClick={() => toggleEnabled(false)}>
            <Close />
          </button>
        </div>
        <h1 className="text-center md:text-4xl text-2xl md:my-[5%] my-[10%] font-extrabold  text-[#F8F4F2] ">
          Thanks for signing up to get Reeach.
        </h1>
        <p className="text-center md:mb-[5%]  mb-[10%]   text-[#F8F4F2] ">
          {" "}
          Keep an eye on your inbox. We can’t wait to get this party started!
        </p>
        <button
          onClick={() => toggleEnabled(false)}
          className=" md:w-[20%] w-[40%] py-2 text-[#F8F4F2] border-[#F8F4F2] hover:bg-[#F8F4F2] font-medium hover:text-black rounded-full border-2 "
        >
          Go back
        </button>
      </div>
    </div>
  );
};

export default SubmissionPopUp;
