import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const SelectField = ({
  label,
  required,
  placeholder,
  options,
  value,
  onChange,
  maxSelections,
  multiple,
  errorState,
  setErrorState,
}: {
  label: string;
  required?: boolean;
  placeholder?: string;
  options: { id: string; name: string; value: string }[];
  value: { id: string; name: string; value: string } | { id: string; name: string; value: string }[];
  onChange: (value: { id: string; name: string; value: string } | { id: string; name: string; value: string }[]) => void;
  maxSelections?: number;
  multiple?: boolean;
  errorState?: boolean;
  setErrorState?: (value: boolean) => void;
}) => {

  const removeFromSelected = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, id: string) => {
    e.preventDefault();
    if (!Array.isArray(value)) return;
    const newValue = value.filter((v) => v.id !== id);
    onChange(newValue);
  }

  const handleChange = (option: { id: string; name: string; value: string } | { id: string; name: string; value: string }[]) => {
    if (setErrorState) setErrorState(false);
    if (multiple && Array.isArray(option) && maxSelections && option.length > maxSelections) return;
    onChange(option);
  }

  const shouldUsePlaceholder = !value || (Array.isArray(value) && value.length === 0) || (value && Object.keys(value).length === 0);

  const valueText = shouldUsePlaceholder ? (
    <span className='ml-3 block truncate text-gray-400'>{placeholder}</span>
  ) : !Array.isArray(value) ? (
    <span className='ml-3 block truncate'>{value.name}</span>
  ) : (
    <span className='flex flex-wrap justify-start items-center gap-y-1.5'>
      {value.map((v) => (
        <span key={v.id} className='ml-3 block truncate bg-gray-200 rounded-2xl py-0.5 px-4'>
          <span className='mr-3'>{v.name}</span>
          <span className='font-medium cursor-pointer' onClick={(e) => removeFromSelected(e, v.id)}>x</span>
        </span>
      ))}
    </span>
  );

  return (
    <Listbox value={value} onChange={handleChange} multiple={multiple}>
      {({ open }) => (
        <>
          <Listbox.Label className='block text-base leading-6 text-gray-900'>
            {label}
            {required && <span className='text-red-500'>*</span>}
          </Listbox.Label>
          <div className='relative mt-2'>
            <Listbox.Button className={classNames(errorState ? 'ring-red-500' : 'ring-gray-300', 'relative w-full cursor-default rounded-md bg-white py-1.5 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset focus:outline-none focus:ring-2 focus:ring-gray-300 sm:text-sm sm:leading-6')}>
              <span className='flex items-center'>{valueText}</span>
            </Listbox.Button>

            <Transition show={open} as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
              <Listbox.Options className='absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                {options.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-900', 'relative cursor-default select-none py-2 pr-9')
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className='flex items-center'>
                          <span
                            className={classNames(
                              selected || (Array.isArray(value) && value.find((v) => v.id === option.id)) ? 'font-semibold' : 'font-normal',
                              'ml-3 block truncate'
                            )}
                          >
                            {option.name}
                          </span>
                        </div>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default SelectField;
