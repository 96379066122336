import React, { useState, useEffect } from "react";
import { images } from "../../../utils/images";

import "./LoveCreators.scss";

const loveReasons = [
  {
    title: "Twitter/Linkedin",
    description: [
      "No more replying to filled DMs and missing on brands",
      "Let brands know about all the channels you can promote",
      "Connect with brands at your preferred time through the link in bio",
    ],
  },
  {
    title: "Newsletters",
    description: [
      "Make your ad booking self-serve",
      "Your streamlined process becomes your USP for brands",
      "Keep your earnings and ad delivery in one dashboard",
    ],
  },
  {
    title: "Instagram/Tik Tok",
    description: [
      "No more replying to filled DMs and missing brands",
      "Let brands know about all the channels you can promote",
      "Connect with brands at your preferred time through the link in bio",
    ],
  },
  {
    title: "Podcasts",
    description: [
      "Automate your speaker acquisition",
      "Make your streamlined process a unique experience for your brands",
      "Keep your earnings and ad delivery in one dashboard",
    ],
  },
  {
    title: "Media Companies",
    description: [
      "Make your ad booking self-serve",
      "Your streamlined process becomes your USP for brands",
      "Keep your earnings and ad delivery in one dashboard",
    ],
  },
  {
    title: "Youtube",
    description: [
      "Share your past results and selected YT stats that help brands choose you",
      "Make your streamlined process a unique experience for your brands",
      "Keep track of your ad booking slots for content production",
    ],
  },
];

const MobileAccordion = () => {
  const [isOpen, setIsOpen] = useState<any>([]);
  useEffect(() => {
    setIsOpen(new Array(loveReasons.length).fill(false));
  }, [loveReasons]);

  const toggleAccordion = (index) => {
    const newIsOpen = [...isOpen];
    newIsOpen[index] = !newIsOpen[index];
    setIsOpen(newIsOpen);
  };
  const MobileDropDown = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={11}
      fill="none"
      {...props}
    >
      <path
        stroke={!isOpen[props.index] ? "#303030" : "white"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m1.444 9.42 6.991-7.63 6.992 7.63"
        style={{
          stroke: !isOpen[props.index] ? "#303030" : "white",

          strokeOpacity: 1,
        }}
      />
    </svg>
  );

  return (
    <div className="accordion w-full overflow-hidden">
      {loveReasons.map((reason, index) => (
        <div key={index} className="mt-10">
          <button
            className={`relative  z-20 bg-white w-[60vw] flex flex-row items-center justify-between rounded-full font-medium border-2 border-black text-[#303030] py-2 px-4 text-left ${
              isOpen[index] ? "bg-black text-white" : ""
            }`}
            onClick={() => toggleAccordion(index)}
          >
            <p className="tag">{reason.title}</p>
            <MobileDropDown
              index={index}
              className={`transition-transform ease-in-out duration-300 origin-center transform ${
                !isOpen[index] ? "rotate-180 text-white " : ""
              }`}
            />
          </button>

          <div
            className={` bg-white relative  border-2 border-black rounded-md py-4 px-4 -mt-4 z-10 accordion-content transition-transform ease-in-out duration-300 origin-top ${
              isOpen[index] ? "scale-y-100 h-auto " : "scale-y-0 h-0 "
            }`}
          >
            <ul className="description px-4 z-10 ">
              {reason.description.map((desc, i) => (
                <li className="text-black list-disc  z-10" key={i}>
                  {desc}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

const LoveCreators = ({ styles }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [popups, setPopups] = useState({
    twitter: false,
    newsletter: false,
    instagram: false,
    podcast: false,
    media: false,
    youtube: false,
  });

  const togglePopupOn = (selection: string) => {
    setPopups({
      twitter: false,
      newsletter: false,
      instagram: false,
      podcast: false,
      media: false,
      youtube: false,
      [selection]: true,
    });
  };

  const togglePopupOff = () => {
    setPopups({
      twitter: false,
      newsletter: false,
      instagram: false,
      podcast: false,
      media: false,
      youtube: false,
    });
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="landing-love-creators-section" id="landing-love-creators">
      <div className="title">For the love of all creators</div>
      {isMobile ? (
        <MobileAccordion />
      ) : (
        <div className="tags-container">
          <div
            className="tag-area"
            onMouseEnter={() => togglePopupOn("twitter")}
            onMouseLeave={togglePopupOff}
          >
            <div className="tag">Twitter/Linkedin</div>
            {popups.twitter ? (
              <div className="tag-popup">
                <div className="tag-name">Twitter/Linkedin</div>
                <ul className="description">
                  <li>No more replying to filled DMs and missing on brands</li>
                  <li>
                    Let brands know about all the channels you can promote
                  </li>
                  <li>
                    Connect with brands at your preferred time through the link
                    in bio
                  </li>
                </ul>
              </div>
            ) : null}
          </div>
          <div
            className="tag-area"
            onMouseEnter={() => togglePopupOn("newsletter")}
            onMouseLeave={togglePopupOff}
          >
            <div className="tag">Newsletters</div>
            {popups.newsletter ? (
              <div className="tag-popup">
                <div className="tag-name">Newsletters</div>
                <ul className="description">
                  <li>Make your ad booking self-serve</li>
                  <li>Your streamlined process becomes your USP for brands</li>
                  <li>Keep your earnings and ad delivery in one dashboard</li>
                </ul>
              </div>
            ) : null}
          </div>
          <div
            className="tag-area"
            onMouseEnter={() => togglePopupOn("instagram")}
            onMouseLeave={togglePopupOff}
          >
            <div className="tag">Instagram/Tik Tok</div>
            {popups.instagram ? (
              <div className="tag-popup">
                <div className="tag-name">Instagram/Tik Tok</div>
                <ul className="description">
                  <li>No more replying to filled DMs and missing brands</li>
                  <li>
                    Let brands know about all the channels you can promote
                  </li>
                  <li>
                    Connect with brands at your preferred time through the link
                    in bio
                  </li>
                </ul>
              </div>
            ) : null}
          </div>
          <div
            className="tag-area"
            onMouseEnter={() => togglePopupOn("podcast")}
            onMouseLeave={togglePopupOff}
          >
            <div className="tag">Podcasts</div>
            {popups.podcast ? (
              <div className="tag-popup">
                <div className="tag-name">Podcasts</div>
                <ul className="description">
                  <li>Automate your speaker acquisition</li>
                  <li>
                    Make your streamlined process a unique experience for your
                    brands
                  </li>
                  <li>Keep your earnings and ad delivery in one dashboard</li>
                </ul>
              </div>
            ) : null}
          </div>
          <div
            className="tag-area"
            onMouseEnter={() => togglePopupOn("media")}
            onMouseLeave={togglePopupOff}
          >
            <div className="tag">Media Companies</div>
            {popups.media ? (
              <div className="tag-popup">
                <div className="tag-name">Media Companies</div>
                <ul className="description">
                  <li>Make your ad booking self-serve</li>
                  <li>Your streamlined process becomes your USP for brands</li>
                  <li>Keep your earnings and ad delivery in one dashboard</li>
                </ul>
              </div>
            ) : null}
          </div>
          <div
            className="tag-area"
            onMouseEnter={() => togglePopupOn("youtube")}
            onMouseLeave={togglePopupOff}
          >
            <div className="tag">Youtube</div>
            {popups.youtube ? (
              <div className="tag-popup">
                <div className="tag-name">Youtube</div>
                <ul className="description">
                  <li>
                    Share your past results and selected YT stats that help
                    brands choose you
                  </li>
                  <li>
                    Make your streamlined process a unique experience for your
                    brands
                  </li>
                  <li>
                    Keep track of your ad booking slots for content production
                  </li>
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      )}
      <img
        src={images.girlWithClouds}
        alt="creators-img"
        id="girl-with-clouds"
        className="hidden md:block"
      />
    </div>
  );
};

export default LoveCreators;
