import React from "react";
import { Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { icons, images } from "../../../utils/images";
import LoginButton from "./LoginButton/LoginButton.tsx";
import "./Header.scss";

const Header = () => {
  const navigate = useNavigate();

  const handleRootClick = () => {
    return navigate("/");
  };

  return (
    <div className="header-container">
      <div className="left-section">
        <img
          src={icons.brandLogo}
          alt="Reeach logo"
          className=" h-[3vh] md:h-[5vh] "
          onClick={handleRootClick}
        />
        {/* <img
          src={images.creatorfolioSvg}
          alt="Creatorfolio logo"
          className="creatorfolio-logo"
        /> */}
      </div>
      <div className="right-section">
        {/* <HeaderLink href="/createrfolio/myWork" text="My work" />
        <HeaderLink href="/createrfolio/services" text="Services" />
        <HeaderLink href="/" text="Links" />
        <HeaderLink href="/" text="Contact" /> */}
        {/* <Link href="/createrfolio#creatorfolio-intro" className="header-link">
          Home
        </Link>
        <Link
          href="/createrfolio#creatorfolio-services"
          className="header-link"
        >
          Services
        </Link>
        <Link href="/createrfolio#creatorfolio-links" className="header-link">
          Links
        </Link>
        <Link href="/createrfolio#creatorfolio-stats" className="header-link">
          Stats
        </Link>
        <Link href="/createrfolio#creatorfolio-socials" className="header-link">
          Socials
        </Link> */}
        {/* <LoginButton /> */}
        <Link href="#landing-join-form" className="start-link">
          <button className="creator-btn">Start for Free</button>
        </Link>
      </div>
    </div>
  );
};

export default Header;
