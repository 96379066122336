import React, { Fragment, useState } from 'react';
import { Listbox, Combobox, Transition } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Autocomplete = ({
  label,
  required,
  placeholder,
  options,
  value,
  onChange,
  errorState,
  setErrorState,
}: {
  label: string;
  required?: boolean;
  placeholder?: string;
  options: { id: string; name: string; value: string }[];
  value: { id: string; name: string; value: string } | { id: string; name: string; value: string }[];
  onChange: (value: { id: string; name: string; value: string } | { id: string; name: string; value: string }[]) => void;
  errorState?: boolean;
  setErrorState?: (value: boolean) => void;
}) => {
  const [query, setQuery] = useState('');

  const filteredOptions =
    query === '' ? options : options.filter((option) => option.name.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')));

  const handleChange = (option: { id: string; name: string; value: string } | { id: string; name: string; value: string }[]) => {
    if (setErrorState) setErrorState(false);
    onChange(option);
  };

  const getValueText = (v) => {
    return v.name;
  };

  return (
    <>
      <label className='block text-base leading-6 text-gray-900'>
        {label}
        {required && <span className='text-red-500'>*</span>}
      </label>

      <div className='relative mt-2'>
        {/* @ts-ignore: Ignore next line since multiple property has a TS bug */}
        <Combobox value={value} onChange={handleChange} multiple={false}>
          <Combobox.Input
            className={classNames(errorState ? 'ring-red-500' : 'ring-gray-300', 'block w-full rounded-md border-0 py-2 text-gray-900 ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6')}
            displayValue={(opt) => getValueText(opt)}
            onChange={(event) => setQuery(event.target.value)}
            placeholder={placeholder}
          />
          {/* <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                    </Combobox.Button> */}
          <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0' afterLeave={() => setQuery('')}>
            <Combobox.Options className='absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
              {filteredOptions.length === 0 && query !== '' ? (
                <div className='relative cursor-default select-none px-4 py-2 text-gray-700'>Nothing found.</div>
              ) : (
                filteredOptions.map((option) => (
                  <Combobox.Option
                    key={option.id}
                    className={({ active }) =>
                      classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-10 pr-4')
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{option.name}</span>
                        {/* {selected ? (
                                <span
                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                    active ? 'text-white' : 'text-teal-600'
                                    }`}
                                >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                                ) : null} */}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </Combobox>
      </div>
    </>
  );
};

export default Autocomplete;
